.centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.unformattedNavLink{
      color: white;
      text-decoration: none;
      font-weight: bold;
      font-size: 20px;
      margin-left: 5px;
      display: block;
      line-height: 40px;
}
.unformattedNavLink:hover{
  color: rgb(157, 193, 230);
}

.unformattedNavLink.active{
  color: #add2ff;
  /* text-decoration: underline; */
  /* line-height: 25px; */
  border-bottom: #add2ff 2px solid;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
