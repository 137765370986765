
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
  .pagesLinks{
    @apply my-2 text-sm font-bold text-center px-2 tracking-wide 
  }
  .btnProduct{
    @apply bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded uppercase
  }
  .centered{
    @apply flex justify-center items-center
  }

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
